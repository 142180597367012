import React, { useState } from "react"
import Header from "../components/Header"
import Footer from "../components/Footer"
import { PageProps } from "gatsby"
import Section from "../components/Section"
import Wrapper from "../components/Wrapper"
import { useForm } from "react-hook-form"
import { FaCheckCircle } from "react-icons/fa"
import SEO from "../components/SEO"

export default function Preisgestaltung(props: PageProps) {
  const [status, setStatus] = useState(false)
  const { register, handleSubmit, reset } = useForm()
  const onSubmit = data => {
    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: new URLSearchParams(data).toString(),
    })
      .then(() => {
        reset()
        setStatus(true)
      })
      .catch(error => alert(error))
  }
  return (
    <React.Fragment>
      <SEO titleAddition="Preisgestaltung" />
      <Header />
      <div className="w-full flex flex-col text-gray-700">
        <Section>
          <Wrapper>
            <div
              className="p-8 sm:p-16 flex flex-col"
              css={{
                minHeight: "calc(100vh - 5rem)",
              }}
            >
              <div className="space-y-8 mb-16">
                <div className="text-2xl lg:text-3xl text-blue-atl font-bold">
                  Angebot anfordern
                </div>
                <div className="text-xl text-gray-600 font-bold">
                  Jedes Unternehmen ist einzigartig und unterscheidet sich in
                  Größe, Komplexität und Zielsetzung … und damit ändert sich
                  auch der Umfang und der Bedarf für die Einführung von TaxIQ.
                  Nehmen Sie Kontakt zu uns auf und wir finden die passgenaue
                  Lösung, die zu Ihren Zielen und Ihrem Budget passt.
                </div>
                <div className="space-y-2">
                  <div className="font-bold text-xl">
                    1. Füllen Sie dieses Formular mit Ihren Kontaktdaten aus.
                  </div>
                </div>
                <div>
                  <div className="font-bold text-xl">
                    2. Wir setzen uns mit Ihnen in Verbindung und ermitteln
                    gemeinsam, welche Anforderungen Sie haben.
                  </div>
                </div>
                <div>
                  <div className="font-bold text-xl">
                    3. Sie wählen die Alternative, die Ihren Anforderungen am
                    besten entspricht.
                  </div>
                </div>
              </div>
              <form
                onSubmit={handleSubmit(onSubmit)}
                method="post"
                name="pricing"
                data-netlify="true"
                netlify-honeypot="bot-field"
              >
                <div className="flex flex-col space-y-12">
                  <div className="flex flex-col sm:flex-row ">
                    <span className="flex-1 space-y-2">
                      <label htmlFor="email">Geschäftsmail*</label>
                      <input
                        type="email"
                        className="w-full p-2 border border-gray-200"
                        {...register("email", {
                          required: true,
                          maxLength: 30,
                        })}
                      />
                    </span>
                  </div>
                  <div className="flex flex-col sm:flex-row space-y-8 sm:space-y-0 sm:space-x-8">
                    <span className="flex-1 space-y-2">
                      <label htmlFor="firstName">Vorname*</label>
                      <input
                        className="w-full p-2 border border-gray-200"
                        {...register("firstName", {
                          required: true,
                          maxLength: 30,
                        })}
                      />
                    </span>
                    <span className="flex-1 space-y-2">
                      <label htmlFor="lastName">Nachname*</label>
                      <input
                        className="w-full p-2 border border-gray-200"
                        {...register("lastName", {
                          required: true,
                          maxLength: 30,
                        })}
                      />
                    </span>
                  </div>
                  <div className="flex flex-col sm:flex-row space-y-8 sm:space-y-0  sm:space-x-8">
                    <span className="flex-1 space-y-2">
                      <label htmlFor="companyName">Unternehmen*</label>
                      <input
                        className="w-full p-2 border border-gray-200"
                        {...register("companyName", {
                          required: true,
                          maxLength: 30,
                        })}
                      />
                    </span>
                  </div>
                  <div className="flex flex-col sm:flex-row space-y-8 sm:space-y-0  sm:space-x-8">
                    <span className="flex-1 space-y-2">
                      <label htmlFor="job">Jobbezeichnung*</label>
                      <input
                        className="w-full p-2 border border-gray-200"
                        {...register("job", {
                          required: true,
                          maxLength: 30,
                        })}
                      />
                    </span>
                  </div>
                  <div className="flex flex-col space-y-2">
                    <div className="font-bold">DSGVO-Einverständnis:</div>
                    <span className="flex-1 space-x-2">
                      <input
                        type="checkbox"
                        id="dsgvo"
                        {...register("dsgvo", { required: true })}
                      />
                      <label htmlFor="dsgvo">
                        Ich willige ein, dass diese Website meine übermittelten
                        Informationen speichert, sodass meine Anfrage
                        beantwortet werden kann.
                      </label>
                    </span>
                  </div>
                  <input type="hidden" {...register("bot-field")} />
                  <input
                    type="hidden"
                    value="pricing"
                    {...register("form-name")}
                  />
                  <div className="flex">
                    <input
                      className="p-2 px-8 bg-blue-atl text-white hover:bg-blue-900 transition-color duration-300 ease-in rounded"
                      value="Absenden"
                      type="submit"
                    />
                  </div>
                </div>
              </form>
              {status && (
                <div className="mt-8 bg-green-600 text-white rounded p-4 flex items-center">
                  <span className="mr-4 text-xl">
                    <FaCheckCircle />
                  </span>{" "}
                  <span>
                    Ihre Nachricht wurde erfolgreich verschickt. Wir werden uns
                    schnellstmöglich bei Ihnen melden!
                  </span>
                </div>
              )}
            </div>
          </Wrapper>
        </Section>
        <Section>
          <Wrapper>
            <Footer />
          </Wrapper>
        </Section>
      </div>
    </React.Fragment>
  )
}
